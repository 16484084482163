import './App.css';
import Banner from './Banner';
import Navbar from './Navbar';
import requests from './requests';
import Row from './Row';

function App() {
  return (
    <div className="app">
      <div className="scrollbar-container">
        <Navbar />
        <Banner />
        <Row title="NETFLIX ORIGNALS" fetchUrl={requests.fetchNetflixOriginals} isLargeRow />
        <Row title="UPCOMING MOVIES" fetchUrl={requests.fetchUpcomingMovies} isLargeRow />
        <Row title="Airing Today" fetchUrl={requests.fetchAiringToday} isLargeRow />
        <Row title="Trending Now" fetchUrl={requests.fetchTrending} isLargeRow={false} />
        <Row title="Popular with Users in TV" fetchUrl={requests.fetchPopularTV} isLargeRow={false} />
        <Row title="Popular with Users in Movies" fetchUrl={requests.fetchPopularMovies} isLargeRow={false} />
        <Row title="Top Rated in Movies" fetchUrl={requests.fetchTopRatedMovies} isLargeRow={false} />
        <Row title="Top Rated in TV" fetchUrl={requests.fetchTopRatedTV} isLargeRow={false} />
        <Row title="Action Movies" fetchUrl={requests.fetchActionMovies} isLargeRow={false} />
        <Row title="Comedy Movies" fetchUrl={requests.fetchComedyMovies} isLargeRow={false} />
        <Row title="Horror Movies" fetchUrl={requests.fetchHorrorMovies} isLargeRow={false} />
        <Row title="Romance Movies" fetchUrl={requests.fetchRomanceMovies} isLargeRow={false} />
        <Row title="Documenraries" fetchUrl={requests.fetchDocumentaries} isLargeRow={false} />
      </div>
    </div>
  );
}

export default App;
