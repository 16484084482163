import React, { useEffect, useState } from 'react'
import YouTube from 'react-youtube';
import axios from './axios';
import './Row.css';
import movieTrailer from 'movie-trailer';

const base_url = "https://image.tmdb.org/t/p/original/";
function Row({ title, fetchUrl, isLargeRow }) {

  const [movies, setMovies] = useState([]);
  const [trailerUrl, setTrailerUrl] = useState('');
  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovies(request.data.results);
      return request;
    }
    fetchData();
  }, [fetchUrl]);

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  async function TVTrailer(movie) {
    let titleLowerCaseArray = JSON.stringify(title.split(/[\s,]+/)).toLocaleLowerCase();
    if (titleLowerCaseArray.includes('movies')) {
      const request = await axios.get(`movie/${movie.id}/videos?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`)
      setTrailerUrl(request.data.results[1]?.key || request.data.results[0]?.key)
    }
    else {
      const request = await axios.get(`tv/${movie.id}/videos?api_key=${process.env.REACT_APP_API_KEY}&language=en-US`);
      setTrailerUrl(request.data.results[1]?.key || request.data.results[0]?.key)
    }
  }

  const handleClick = (movie) => {
    if (trailerUrl) {
      setTrailerUrl('');
    }
    else {
      movieTrailer(movie?.name || movie?.original_name || movie?.title || "")
        .then(url => {
          if (!url) {
            TVTrailer(movie)
          }
          else {
            const urlParams = new URLSearchParams(new URL(url).search);
            setTrailerUrl(urlParams.get("v"));
          }
        }).catch(error => console.log(error));
    }
  };
  return (
    <div className="row">
      <h2>{title}</h2>
      <div className="row-posters">
        {
          movies.map(movie => (
            <img key={movie.id} onClick={() => handleClick(movie)} className={`row-poster ${isLargeRow && "row-posterLarge"}`} src={`${base_url}${isLargeRow ? movie.poster_path : movie.backdrop_path}`} alt={movie.original_title} />
          ))
        }
      </div>
      {trailerUrl && <YouTube videoId={trailerUrl} opts={opts} />}
    </div>
  )
}

export default Row
