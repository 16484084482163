import React, { useEffect, useState } from 'react'
import './Navbar.css'

function Navbar() {

  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      }
      else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, [])
  return (
    <div className={`nav ${show && "nav-black"}`}>
      {/* <img className="nav__logo" src="https://upload.wikimedia.org/wikipedia/commons/0/0f/Logo_Netflix.png" alt="Netflix Logo" /> */}
      <img className="nav-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1597px-Netflix_2015_logo.svg.png" alt="Netflix Logo" />

      <img className="nav-avatar" src="https://pbs.twimg.com/profile_images/1240119990411550720/hBEe3tdn_400x400.png" alt="Netflix Logo" />

    </div>
  )
}

export default Navbar
